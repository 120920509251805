import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'
import * as locales from '@mui/material/locale'
import { createTheme, css, ThemeProvider, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import * as localesDatePicker from '@mui/x-date-pickers/locales'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { Hydrate, QueryCache, QueryClient } from '@tanstack/react-query'
import {
  Persister,
  PersistQueryClientProvider
} from '@tanstack/react-query-persist-client'
import { RefreshTokenHandler } from 'components/auth/RefreshTokenHandler'
import { SeoApp } from 'components/seo/SeoApp'
import { STORAGE_KEYS_PREFIX } from 'constants/storage'
import cs from 'date-fns/locale/cs'
import en from 'date-fns/locale/en-US'
import { SessionProvider } from 'next-auth/react'
import { appWithTranslation } from 'next-i18next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { Router, useRouter } from 'next/router'
import Script from 'next/script'
import NProgress from 'nprogress'
import React, { useEffect, useRef, useState } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Toaster } from 'react-hot-toast'
import { hotjar } from 'react-hotjar'
import { fonts, nProgress } from 'styles/global'
import { PRIMARY_COLOR, theme } from 'styles/theme'
import { useUserTracking } from 'utils/analytics'
import { hasWindow, sessionStorageClient } from 'utils/dom'
import nextI18NextConfig from '../../next-i18next.config.js'

config.autoAddCss = false

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(d => ({
    default: d.ReactQueryDevtools
  }))
)

if (process.env.NEXT_PUBLIC_ENABLE_MOCKING === 'true' && hasWindow()) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('../tools/mocks/browser')
  worker.start()
}

Router.events.on('routeChangeStart', () => {
  sessionStorageClient.setItem('prevPage', window.location.href || '')
  NProgress.start()
})
Router.events.on('routeChangeError', () => NProgress.done())
Router.events.on('routeChangeComplete', () => NProgress.done())

function App({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: true,
            retry: 3,
            staleTime: 5 * 1000,
            cacheTime: 10 * 60 * 1000
          },
          mutations: {
            retry: 5
          }
        },
        queryCache: new QueryCache({
          // eslint-disable-next-line
          onError: (error: any) => console.error(error)
        })
      })
  )
  const queryPersisterRef = useRef<Persister>()

  const router = useRouter()
  const themeWithLocale = React.useMemo(
    () =>
      createTheme(
        theme,
        locales[router.locale === 'en' ? 'enUS' : 'csCZ'],
        localesDatePicker[router.locale === 'en' ? 'enUS' : 'csCZ']
      ),
    [router.locale]
  )

  const { breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('sm'))

  const [interval, setInterval] = useState(0)
  const [showDevtools, setShowDevtools] = useState(
    process.env.NEXT_PUBLIC_ENABLE_REACT_QUERY_DEVTOOLS === 'true'
  )

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools(old => !old)
  }, [])

  if (!queryPersisterRef.current) {
    queryPersisterRef.current = createSyncStoragePersister({
      storage: hasWindow() ? window.localStorage : undefined,
      key: STORAGE_KEYS_PREFIX + '.queries'
    })
  }

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_ENV === 'production' ||
      process.env.NEXT_PUBLIC_ENV === 'staging'
    ) {
      hotjar.initialize(
        parseInt(process.env.NEXT_PUBLIC_HOTJAR_SITE_ID || '0'),
        1
      )
    }
  }, [])

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="theme-color" content={PRIMARY_COLOR} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      {(process.env.NEXT_PUBLIC_ENV === 'production' ||
        process.env.NEXT_PUBLIC_ENV === 'staging') && (
        <>
          <Script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
          ></Script>
          <Script id="google-analytics">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}');
          `}
          </Script>
        </>
      )}

      <SessionProvider session={pageProps.session} refetchInterval={interval}>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister: queryPersisterRef.current }}
        >
          <Hydrate state={pageProps.dehydratedState}>
            {showDevtools &&
              process.env.NEXT_PUBLIC_ENABLE_REACT_QUERY_DEVTOOLS ===
                'true' && (
                <React.Suspense fallback={null}>
                  <ReactQueryDevtoolsProduction
                    initialIsOpen={false}
                    position="bottom-right"
                  />
                </React.Suspense>
              )}

            <SeoApp />

            <ThemeProvider theme={themeWithLocale}>
              <CssBaseline />
              <GlobalStyles
                styles={css`
                  ${nProgress}
                  ${fonts}
                `}
              />
              <AppAnalytics />

              <main>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={router.locale === 'en' ? en : cs}
                >
                  <Component {...pageProps} />
                </LocalizationProvider>
              </main>

              <RefreshTokenHandler setInterval={setInterval} />
              <Toaster
                position={isSmallDevice ? 'top-right' : 'bottom-right'}
              />
            </ThemeProvider>
          </Hydrate>
        </PersistQueryClientProvider>
      </SessionProvider>
    </>
  )
}

function AppAnalytics() {
  // component to trigger tracking inside providers
  useUserTracking()

  return <></>
}

export default appWithTranslation(App, nextI18NextConfig)
