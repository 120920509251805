/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Planner API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  SessionDTO,
  SessionUserSignUpEmailDTO,
  SessionUserSignUpDTO
} from './dotu.schemas'
import { dotuAxiosInstance } from '../../axios';



// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getApiSession = (
    
 options?: SecondParameter<typeof dotuAxiosInstance>,signal?: AbortSignal
) => {
      return dotuAxiosInstance<SessionDTO>(
      {url: `/api/Session`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiSessionQueryKey = () => [`/api/Session`] as const;
  

    
export const getGetApiSessionQueryOptions = <TData = Awaited<ReturnType<typeof getApiSession>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiSession>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiSession>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiSessionQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiSession>>> = ({ signal }) => getApiSession(requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetApiSessionQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSession>>>
export type GetApiSessionQueryError = unknown

export const useGetApiSession = <TData = Awaited<ReturnType<typeof getApiSession>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiSession>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiSessionQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

export const getApiSessionSessionToken = (
    sessionToken: string,
 options?: SecondParameter<typeof dotuAxiosInstance>,signal?: AbortSignal
) => {
      return dotuAxiosInstance<SessionDTO>(
      {url: `/api/Session/${sessionToken}`, method: 'get', signal
    },
      options);
    }
  

export const getGetApiSessionSessionTokenQueryKey = (sessionToken: string,) => [`/api/Session/${sessionToken}`] as const;
  

    
export const getGetApiSessionSessionTokenQueryOptions = <TData = Awaited<ReturnType<typeof getApiSessionSessionToken>>, TError = unknown>(sessionToken: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiSessionSessionToken>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiSessionSessionToken>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiSessionSessionTokenQueryKey(sessionToken);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiSessionSessionToken>>> = ({ signal }) => getApiSessionSessionToken(sessionToken, requestOptions, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(sessionToken), ...queryOptions}}

export type GetApiSessionSessionTokenQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSessionSessionToken>>>
export type GetApiSessionSessionTokenQueryError = unknown

export const useGetApiSessionSessionToken = <TData = Awaited<ReturnType<typeof getApiSessionSessionToken>>, TError = unknown>(
 sessionToken: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiSessionSessionToken>>, TError, TData>, request?: SecondParameter<typeof dotuAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiSessionSessionTokenQueryOptions(sessionToken,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

export const postApiSessionSendSignUpEmail = (
    sessionUserSignUpEmailDTO: SessionUserSignUpEmailDTO,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<void>(
      {url: `/api/Session/SendSignUpEmail`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: sessionUserSignUpEmailDTO
    },
      options);
    }
  


export const getPostApiSessionSendSignUpEmailMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiSessionSendSignUpEmail>>, TError,{data: SessionUserSignUpEmailDTO}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiSessionSendSignUpEmail>>, TError,{data: SessionUserSignUpEmailDTO}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiSessionSendSignUpEmail>>, {data: SessionUserSignUpEmailDTO}> = (props) => {
          const {data} = props ?? {};

          return  postApiSessionSendSignUpEmail(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiSessionSendSignUpEmailMutationResult = NonNullable<Awaited<ReturnType<typeof postApiSessionSendSignUpEmail>>>
    export type PostApiSessionSendSignUpEmailMutationBody = SessionUserSignUpEmailDTO
    export type PostApiSessionSendSignUpEmailMutationError = unknown

    export const usePostApiSessionSendSignUpEmail = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiSessionSendSignUpEmail>>, TError,{data: SessionUserSignUpEmailDTO}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiSessionSendSignUpEmailMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    export const postApiSessionSignUpSessionToken = (
    sessionToken: string,
    sessionUserSignUpDTO: SessionUserSignUpDTO,
 options?: SecondParameter<typeof dotuAxiosInstance>,) => {
      return dotuAxiosInstance<void>(
      {url: `/api/Session/SignUp/${sessionToken}`, method: 'post',
      headers: {'Content-Type': 'application/json-patch+json', },
      data: sessionUserSignUpDTO
    },
      options);
    }
  


export const getPostApiSessionSignUpSessionTokenMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiSessionSignUpSessionToken>>, TError,{sessionToken: string;data: SessionUserSignUpDTO}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiSessionSignUpSessionToken>>, TError,{sessionToken: string;data: SessionUserSignUpDTO}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiSessionSignUpSessionToken>>, {sessionToken: string;data: SessionUserSignUpDTO}> = (props) => {
          const {sessionToken,data} = props ?? {};

          return  postApiSessionSignUpSessionToken(sessionToken,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiSessionSignUpSessionTokenMutationResult = NonNullable<Awaited<ReturnType<typeof postApiSessionSignUpSessionToken>>>
    export type PostApiSessionSignUpSessionTokenMutationBody = SessionUserSignUpDTO
    export type PostApiSessionSignUpSessionTokenMutationError = unknown

    export const usePostApiSessionSignUpSessionToken = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiSessionSignUpSessionToken>>, TError,{sessionToken: string;data: SessionUserSignUpDTO}, TContext>, request?: SecondParameter<typeof dotuAxiosInstance>}
) => {
    
      const mutationOptions = getPostApiSessionSignUpSessionTokenMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    