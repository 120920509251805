export const STORAGE_KEYS_PREFIX = 'dotu'

export type SESSION_STORAGE_KEYS =
  | 'currPage'
  | 'prevPage'
  | 'calendarInvitation'

export type LOCAL_STORAGE_KEYS =
  | 'queries'
  | 'calendarParams'
  | 'calendarFilters'
  | 'displayedAccounts'
  | 'displayedTimeZoneModal'
  | 'displayedTimeZone'
  | 'displayedAccountsTab'
  | 'showLinkModal'
