import { useGetApiSession } from 'api/generated/dotu/session'
import { useSession } from 'next-auth/react'
import { useEffect } from 'react'
import { hotjar } from 'react-hotjar'

export function useUserTracking() {
  const session = useSession()
  const { data: sessionData } = useGetApiSession({
    query: {
      enabled: session.status === 'authenticated'
    }
  })

  useEffect(() => {
    if (
      (process.env.NEXT_PUBLIC_ENV === 'production' ||
        process.env.NEXT_PUBLIC_ENV === 'staging') &&
      session.status === 'authenticated' &&
      hotjar.initialized() &&
      sessionData
    ) {
      const user = {
        id: sessionData.user?.id ? sessionData.user.id.toString() : null,
        email: sessionData.user?.email ?? null,
        name: sessionData.user?.name ?? null
      }

      hotjar.identify(user.id, user)
      window.gtag('set', 'user_id', user.id ?? 'anonymous')
    }
  }, [session.status, sessionData])
}
