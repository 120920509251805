import { alpha, createTheme } from '@mui/material/styles'
import NextLink from 'next/link'
import { Ref, forwardRef } from 'react'
import { inter, playfairDisplay } from './fonts'

export const PRIMARY_COLOR = '#ea4c51'

const LinkBehavior = forwardRef(function LinkBehavior(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { href, ...props }: any,
  ref: Ref<HTMLAnchorElement>
) {
  return <NextLink href={href} ref={ref} {...props} />
})

const BASE_STATUS_COLORS = {
  confirmed: '#74D825',
  declined: '#D83A25'
}

const FONT_WEIGHTS = {
  medium: 500,
  semibold: 600,
  bold: 700,
  extraBold: 800
}

const defaultTheme = createTheme()

export const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR
    },
    error: {
      main: '#FF9900'
    },
    background: {
      default: '#f9f2ea',
      paper: '#fffbf7'
    },
    text: {
      primary: '#111111'
    },
    processStatus: {
      confirmed: BASE_STATUS_COLORS.confirmed,
      declined: BASE_STATUS_COLORS.declined,
      pending: '#FFA800',
      personal: '#999494'
    },
    castStatus: {
      default: {
        accept: BASE_STATUS_COLORS.confirmed,
        decline: BASE_STATUS_COLORS.declined
      },
      pending: '#FFA800',
      accepted: '#00ACD1',
      confirmed: BASE_STATUS_COLORS.confirmed,
      declined: BASE_STATUS_COLORS.declined
    }
  },
  shape: {
    borderRadius: 12
  },
  typography: {
    fontFamily: inter.style.fontFamily,
    h1: {
      fontFamily: playfairDisplay.style.fontFamily,
      fontWeight: FONT_WEIGHTS.extraBold
    },
    h2: {
      fontFamily: playfairDisplay.style.fontFamily,
      fontWeight: FONT_WEIGHTS.extraBold,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '2.25rem'
      }
    },
    h3: {
      fontFamily: playfairDisplay.style.fontFamily,
      fontWeight: FONT_WEIGHTS.extraBold
    },
    h4: {
      fontFamily: playfairDisplay.style.fontFamily,
      fontWeight: FONT_WEIGHTS.extraBold,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1.5rem'
      }
    },
    h5: {
      fontWeight: FONT_WEIGHTS.bold,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1.25rem'
      }
    },
    h6: {
      fontWeight: FONT_WEIGHTS.bold,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1rem'
      }
    },
    body1: {
      color: alpha('#111111', 0.87),
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '0.875rem'
      }
    },
    body2: {
      color: alpha('#111111', 0.87),
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '0.75rem'
      }
    },
    opacity: {
      high: 0.87,
      medium: 0.7,
      low: 0.6,
      disabled: 0.5
    }
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: FONT_WEIGHTS.medium
        }
      },
      defaultProps: {
        /* @ts-expect-error Temporary fix */
        component: LinkBehavior
      }
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },

    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          [defaultTheme.breakpoints.down('sm')]: {
            fontSize: '0.875rem'
          }
        }
      }
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          [defaultTheme.breakpoints.down('sm')]: {
            fontSize: '0.875rem'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          lineHeight: 1
        },
        sizeLarge: {
          padding: '24px 30px',
          fontSize: 16,
          [defaultTheme.breakpoints.down('sm')]: {
            padding: '21px 28px',
            fontSize: '0.875rem'
          }
        },
        sizeSmall: {
          padding: '14px 24px',
          fontSize: 14
        }
      }
    }
  }
})
