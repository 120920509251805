const ChainedBackend = require('i18next-chained-backend').default
const LocalStorageBackend = require('i18next-localstorage-backend').default

const isBrowser = typeof window !== 'undefined'

/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
  i18n: {
    defaultLocale: 'cs',
    locales: ['cs', 'en']
  },
  localePath: isBrowser
    ? '/locales'
    : require('path').resolve('./public/locales'),
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  backend: {
    backendOptions: [
      {
        prefix: 'dotu.intl.',
        expirationTime: 24 * 60 * 60 * 1000 // 1 day
      }
    ],
    backends: isBrowser ? [LocalStorageBackend] : []
  },
  serializeConfig: false,
  use: isBrowser ? [ChainedBackend] : [],
  saveMissing: process.env.NODE_ENV === 'development' && isBrowser
}
