import { css } from '@mui/material/styles'
import { inter } from './fonts'
import { PRIMARY_COLOR } from './theme'

export const fonts = css`
  * {
    font-family: ${inter.style.fontFamily};
  }
`

export const nProgress = css`
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: ${PRIMARY_COLOR};

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
  }

  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px currentColor, 0 0 5px currentColor;
    opacity: 1;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  }

  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }

  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }
`
