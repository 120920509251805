import { useSession } from 'next-auth/react'
import { useEffect } from 'react'

interface RefreshTokenHandlerProps {
  setInterval: (time: number) => void
}

export function RefreshTokenHandler({ setInterval }: RefreshTokenHandlerProps) {
  const { data: session } = useSession()

  useEffect(() => {
    if (!session || !session.expirationDate) {
      return
    }

    const timeRemaining = Math.max(
      0,
      new Date(session.expirationDate).getTime() - Date.now() - 5 * 60 * 1000
    )

    setInterval(Math.round(timeRemaining / 1000))
  }, [setInterval, session])

  return null
}
