import {
  LOCAL_STORAGE_KEYS,
  SESSION_STORAGE_KEYS,
  STORAGE_KEYS_PREFIX
} from 'constants/storage'

export function scrollToTop() {
  return window.scrollTo({ behavior: 'smooth', top: 0 })
}

export function scrollToElement(elementId: string, spaceTop = 0) {
  const element = document.getElementById(elementId)
  const bodyRect = document.body.getBoundingClientRect().top

  if (element) {
    const elementRect = element.getBoundingClientRect().top
    const elementPosition = elementRect - bodyRect - spaceTop

    window.scrollTo({
      top: elementPosition,
      behavior: 'smooth'
    })
  }
}

export function hasWindow() {
  return typeof window !== 'undefined'
}

export function hasDocument() {
  return typeof document !== 'undefined'
}

export function isEventTargetSpecificTag<K extends keyof HTMLElementTagNameMap>(
  el: Element | EventTarget | null,
  tagName: K
): el is HTMLElementTagNameMap[K] {
  return el instanceof HTMLElement && el.tagName.toLowerCase() === tagName
}

export interface StorageClient<KeyType extends string> {
  setItem: (key: KeyType, value: string) => void

  getItem: (key: KeyType) => string | null | undefined

  removeItem: (key: KeyType) => void | undefined
}

export function getSessionStorageClient<KeyType extends string>(
  prefix: string
): StorageClient<KeyType> {
  function setItem(key: KeyType, value: string) {
    hasWindow() && sessionStorage.setItem(`${prefix}.${key}`, value)
  }

  function getItem(key: KeyType) {
    return hasWindow() ? sessionStorage.getItem(`${prefix}.${key}`) : undefined
  }

  function removeItem(key: KeyType) {
    return hasWindow()
      ? sessionStorage.removeItem(`${prefix}.${key}`)
      : undefined
  }

  return { setItem, getItem, removeItem }
}

export const sessionStorageClient =
  getSessionStorageClient<SESSION_STORAGE_KEYS>(STORAGE_KEYS_PREFIX)

export interface StorageClient<KeyType extends string> {
  setItem: (key: KeyType, value: string) => void

  getItem: (key: KeyType) => string | null | undefined

  removeItem: (key: KeyType) => void | undefined
}

export function getLocalStorageClient<KeyType extends string>(
  prefix: string
): StorageClient<KeyType> {
  function setItem(key: KeyType, value: string) {
    hasWindow() && localStorage.setItem(`${prefix}.${key}`, value)
  }

  function getItem(key: KeyType) {
    return hasWindow() ? localStorage.getItem(`${prefix}.${key}`) : undefined
  }

  function removeItem(key: KeyType) {
    return hasWindow() ? localStorage.removeItem(`${prefix}.${key}`) : undefined
  }

  return { setItem, getItem, removeItem }
}

export const localStorageClient =
  getLocalStorageClient<LOCAL_STORAGE_KEYS>(STORAGE_KEYS_PREFIX)
