import { useRouter } from 'next/router'
import qs from 'query-string'

function addLocale(path: string, locale?: string, defaultLocale?: string) {
  if (!locale || !defaultLocale || locale === defaultLocale) return path
  return `/${locale}${path}`
}

function filterQueryParams(
  queries: qs.ParsedQuery,
  toInclude: string[] | undefined
) {
  if (toInclude == null) return {}
  return Object.fromEntries(
    Object.entries(queries).filter(([key]) => toInclude.includes(key))
  )
}

export function useLanguageAlternatives() {
  const router = useRouter()
  const defaultLocale = 'cs'

  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL
  const path = router.asPath !== '/' ? router.asPath : ''

  const targetUrl = baseUrl ? new URL(path, baseUrl) : undefined

  if (!targetUrl) return []

  const langDefault = {
    href: targetUrl.toString(),
    hrefLang: 'x-default'
  }

  const langAlts =
    router.locales?.map(locale => ({
      href: (locale !== defaultLocale
        ? new URL(`/${locale}${path}`, baseUrl)
        : targetUrl
      ).toString(),
      hrefLang: `${locale}-${process.env.NEXT_PUBLIC_REGION?.toLocaleUpperCase()}`
    })) || []

  return [langDefault, ...langAlts]
}

export function useCanonicalUrl(options?: {
  asPath?: string
  allowQueryParams?: string[]
}) {
  const router = useRouter()
  const url = `${process.env.NEXT_PUBLIC_BASE_URL}${addLocale(
    options?.asPath ?? router.asPath,
    router.locale,
    router.defaultLocale
  )}`

  const parsed = qs.parseUrl(url)
  const newQuery = filterQueryParams(parsed.query, options?.allowQueryParams)

  return qs.stringifyUrl({ ...parsed, query: newQuery })
}

export function useNoindex() {
  const router = useRouter()
  const { pathname } = router

  const noIndexURLs = [
    '/app',
    '/register/',
    '/reset-password/',
    '/invitation-token/'
  ]

  if (process.env.NEXT_PUBLIC_DISALLOW_ROBOTS === 'true') return true

  return noIndexURLs.some(slug => pathname.startsWith(slug))
}
