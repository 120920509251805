import { useTranslation } from 'next-i18next'
import { DefaultSeo } from 'next-seo'
import { useRouter } from 'next/router'
import {
  useCanonicalUrl,
  useLanguageAlternatives,
  useNoindex
} from './Seo.utils'

export function SeoApp() {
  const router = useRouter()
  const languageAlternatives = useLanguageAlternatives()
  const { t } = useTranslation('common')
  const canonicalUrl = useCanonicalUrl()
  const noindex = useNoindex()

  return (
    <DefaultSeo
      titleTemplate="%s | dotu calendar"
      description={t('seo.description')}
      openGraph={{ url: router.asPath, locale: router.locale }}
      facebook={{
        appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID as string
      }}
      languageAlternates={languageAlternatives}
      canonical={canonicalUrl}
      dangerouslySetAllPagesToNoIndex={noindex}
    />
  )
}
