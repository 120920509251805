import axios, { AxiosRequestConfig } from 'axios'
import { getSession, signOut } from 'next-auth/react'

export const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

const guestRequestUrls = [
  'api/auth/providers', // login
  '/api/Session/SendSignUpEmail', // register email
  '/api/User/PasswordResetLink', // reset password email,
  '/api/User/ResetPassword' // reset password
]

axiosInstance.interceptors.request.use(
  async request => {
    if (
      request.url &&
      (request.url.startsWith('/api/Session/') ||
        request.url.startsWith('/api/auth/') ||
        guestRequestUrls.includes(request.url))
    ) {
      return request
    }

    const session = await getSession()
    if (session?.error === 'RefreshAccessTokenError') {
      signOut()
    } else if (session) {
      request.headers.Authorization = `Bearer ${session.accessToken}`
    }
    return request
  },
  error => {
    if (
      error.response.status === 401 &&
      !error.request.url.startsWith('/api/Session/') &&
      !error.request.url.startsWith('/api/auth/')
    ) {
      signOut()
    } else {
      console.error(error)
    }
  }
)

axiosInstance.interceptors.response.use(
  response => {
    if (
      response.status === 401 &&
      !response.request.url.startsWith('/api/Session/') &&
      !response.request.url.startsWith('/api/auth/')
    ) {
      signOut()
    }
    return response
  },
  error => {
    if (
      error.response.status === 401 &&
      !error.request.url.startsWith('/api/Session/') &&
      !error.request.url.startsWith('/api/auth/')
    ) {
      signOut()
    } else {
      console.error(error)
    }
  }
)

export const dotuAxiosInstance = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const response = await axiosInstance({
    ...options,
    ...config,
    baseURL: process.env.NEXT_PUBLIC_API_URL
  })
  return response?.data
}

export const localAxiosInstance = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const response = await axiosInstance({
    ...options,
    ...config,
    baseURL: '/'
  })
  return response?.data
}
